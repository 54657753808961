import styles from './AreaPrefectureList.module.scss'

import { Prefecture } from 'state/ducks/prefecture/models'
import { City } from 'state/ducks/country/models'

import Link from 'next/link'

// hooks
import { replaceLocale } from 'hooks/common/lang'

interface Props {
  areaName: string
  prefectures?: Prefecture[]
  cities?: City[]
  onSelectAreaName?: (prefecture: Prefecture) => void
  locale: string
  countryCode: string
}

const AreaPrefectureList: React.VFC<Props> = ({
  areaName,
  prefectures,
  cities,
  locale,
  onSelectAreaName,
  countryCode = 'jpn',
}: Props): JSX.Element => {
  const getName = (locale, item: Prefecture | City) => {
    return locale === 'ja' ? item.name : item[`${locale}_name`]
  }

  // 言語の修正
  const replacedLocale: string = replaceLocale(locale)

  return (
    <>
      {countryCode === 'twn' ? (
        <>
          {cities?.length > 0 && (
            <ul className={styles.prefAreaList}>
              {cities.map((city: City, index: number) => (
                <li className={styles.prefAreaListItem} key={index}>
                  <Link href={`/${replacedLocale}/twn/city/${city.slug}`}>
                    {getName(locale, city)}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <dl className={styles.prefArea}>
          <dt className={styles.prefAreaTitle}>{areaName}</dt>
          <dd className={styles.prefAreaListWrap}>
            <ul className={styles.prefAreaList}>
              {prefectures?.length > 0 &&
                prefectures.map((prefecture: Prefecture, index: number) => (
                  <li className={styles.prefAreaListItem} key={index}>
                    <a
                      href={`/${replacedLocale}/jpn/city/${prefecture.slug}`}
                      onClick={(e) => {
                        if (onSelectAreaName) {
                          e.preventDefault()
                          onSelectAreaName(prefecture)
                        }
                      }}
                    >
                      {getName(locale, prefecture)}
                    </a>
                    {/* <Link href={`/jpn/city/${prefecture.slug}`}>{getName(locale, prefecture)}</Link> */}
                  </li>
                ))}
            </ul>
          </dd>
        </dl>
      )}
    </>
  )
}

export default AreaPrefectureList
