import styles from './SectionLayout.module.scss'

interface Props {
  children: React.ReactNode
  sectionName: string
  sectionImage?: string
  sectionImageAlt?: string
  title: string
  style?: string
}

const SectionLayout: React.FC<Props> = ({
  children,
  sectionName,
  sectionImage,
  sectionImageAlt,
  title,
  style,
}: Props): JSX.Element => {
  return (
    <section className={`${styles.section} ${styles[sectionName]} ${style ? style : ''}`}>
      <div className={styles.sectionHeading}>
        {sectionImage && <img src={sectionImage} alt={sectionImageAlt} />}
        <h2 className={`${styles.sectionHeadingTitle} ${styles[sectionName]}Title`}>{title}</h2>
      </div>
      {children}
    </section>
  )
}

export default SectionLayout
